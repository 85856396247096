<template>
  <div>
    <b-button
      v-if="label"
      :label="label"
      rounded
      icon-left="save"
      @click="saveForReport"
    />
    <b-tooltip
      v-else
      :label="tooltip"
    >
      <b-button
        rounded
        icon-left="save"
        @click="saveForReport"
      />
    </b-tooltip>
  </div>
</template>

<script>
import { useWorkspaceStore } from '@/stores/workspacestore'
export default {
  name: 'RSaveForReport',
  props: {
    tooltip: {
      type: String,
      default: 'Save to report'
    },
    label: {
      type: String,
      default: ''
    },
    objectName: {
      type: String,
      default: null
    },
    params: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    return { workspaceStore }
  },
  methods: {
    saveForReport: function () {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let params = {
        'func_name': 'r_save_for_report',
        'object_name': this.objectName,
        'function_name': this.params.func_name,
        'out_graph': true
      }
      for (let key in this.params) {
        if (key !== 'func_name') {
          params[key] = this.params[key]
        }
      }
      this.workspaceStore.runRFunction(params)
        .then(data => {
          this.$buefy.toast.open({
            message: 'Analysis saved for report',
            queue: false
          })
          loadingComponent.close()
        })
        .catch(function () {
          loadingComponent.close()
        })
    }
  }
}
</script>
